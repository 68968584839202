let defaultPrice: Price

declare global {
    interface Window {
        _currency: "CZK" | "EUR"
        _price: Record<"CZK" | "EUR", any[]>
    }
}

export class Price {
    public readonly decPoint: any
    public readonly decimals: number
    public readonly thousandsSep: any
    public readonly prefix: any
    public readonly prefixVat: any
    public readonly prefixNoVat: any
    public readonly suffix: any
    public readonly suffixVat: any
    public readonly suffixNoVat: any
    public readonly rate: number
    public readonly vat: number
    public readonly code: string

    constructor(
        decimals: number,
        decPoint: null,
        thousandsSep = null,
        prefix = null,
        prefixVat = null,
        prefixNoVat = null,
        suffix = null,
        suffixVat = null,
        suffixNoVat = null,
        rate = 1,
        vat = 1.21,
        code = "CZK"
    )
    {
        this.decimals = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        this.decPoint = decPoint !== null ? decPoint : "."
        this.thousandsSep = thousandsSep !== null ? thousandsSep : " "
        this.prefix = prefix !== null ? prefix : ""
        this.prefixVat = prefixVat !== null ? prefixVat : ""
        this.prefixNoVat = prefixNoVat !== null ? prefixNoVat : ""
        this.suffix = suffix !== null ? suffix : ""
        this.suffixVat = suffixVat !== null ? suffixVat : ""
        this.suffixNoVat = suffixNoVat !== null ? suffixNoVat : ""
        this.rate = rate
        this.vat = vat
        this.code = code
    }

    public static default(currency: "CZK" | "EUR" | undefined = undefined): Price
    {
        if(currency === undefined) {
            currency = window._currency
        }

        if (!defaultPrice) {
            // @ts-ignore
            defaultPrice = new Price(...window._price[currency])
        }

        return defaultPrice
    }

    public format(
        price: number,
        vat: null | boolean = null,
        addPrefixSuffix: boolean = true,
        addVat: boolean = false,
        addRate: boolean = true,
    )
    {
        if (addRate) {
            price *= this.rate
        }
        if (addVat) {
            price *= this.vat
        }

        let template = "#number#"
        if (addPrefixSuffix) {
            if (vat === true) {
                template = `#prefix##prefixVat#${ template }#suffix##suffixVat#`
            }
            if (vat === false) {
                template = `#prefix##prefixNoVat#${ template }#suffix##suffixNoVat#`
            }
            if (vat === null) {
                template = `#prefix#${ template }#suffix#`
            }
        } else {
            if (vat === true) {
                template = `#prefixVat#${ template }#suffixVat#`
            }
            if (vat === false) {
                template = `#prefixNoVat#${ template }#suffixNoVat#`
            }
        }

        return template
        .replace("#number#", this.number_format(price))
        .replace("#prefix#", this.prefix)
        .replace("#prefixVat#", this.prefixVat)
        .replace("#prefixNoVat#", this.prefixNoVat)
        .replace("#suffix#", this.suffix)
        .replace("#suffixVat#", this.suffixVat)
        .replace("#suffixNoVat#", this.suffixNoVat)
    }


    // stolen from https://locutus.io/php/strings/number_format/
    private number_format(number: number | string): string
    {
        number = (number + "").replace(/[^0-9+\-Ee.]/g, "")
        const n = !isFinite(+number) ? 0 : +number
        let s = ""
        const toFixedFix = function (n: string | number, prec: number) {
            if (("" + n).indexOf("e") === -1) {
                // @ts-ignore
                return +(Math.round(n + "e+" + prec) + "e-" + prec)
            } else {
                const arr = ("" + n).split("e")
                let sig = ""
                if (+arr[1] + prec > 0) {
                    sig = "+"
                }
                // @ts-ignore
                return (+(Math.round(+arr[0] + "e" + sig + (+arr[1] + prec)) + "e-" + prec)).toFixed(prec)
            }
        }
        // @ts-ignore
        s = (this.decimals ? toFixedFix(n, this.decimals).toString() : "" + Math.round(n)).split(".")
        if (s[0].length > 3) {
            // @ts-ignore
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.thousandsSep)
        }
        if ((s[1] || "").length < this.decimals) {
            // @ts-ignore
            s[1] = s[1] || ""
            // @ts-ignore
            s[1] += new Array(this.decimals - s[1].length + 1).join("0")
        }
        // @ts-ignore
        return s.join(this.decPoint)
    }
}
